import * as Chat from "modules/chat";
import * as React from "react";
import * as Agreement from "../agreement";
import * as BankId from "../bank-id";
import * as PhoneId from "../phone-id";
import * as ShareContactsMessage from "../share-contacts";
import * as Base from "../base";
import * as Calculator from "../calculator";
import * as Registration from "../registration";
import * as Restructuring from "../restructuring";
import * as PromoPage from "../promo-page";
import * as SignUp from "../sign-up";
import * as SmileId from "../smile-id";
import * as Card from "../card";
import * as DirectDebit from "../direct-debit";
import { FailureMessage } from "./failure-message";
import { TimeoutMessage } from "./timeout-message";

export interface MessageProps<M extends Chat.Message = any> {
    value: M;
    i?: number;
}

export type Message<P extends MessageProps = MessageProps> =
    React.ComponentType<P>;

export const Message: Message = ({ value }) => {
    if (value.type.startsWith("reg")) {
        const Component = Registration.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value} />;
        }
    } else if (value.type.startsWith("bank-id")) {
        const Component = BankId.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value} />;
        }
    } else if (value.type.startsWith("phone-id")) {
        const Component = PhoneId.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value} />;
        }
    } else if (value.type.startsWith("contacts")) {
        // TODO временно этот шаг не работает со стороны бекенда
        //  подробности тут https://support.google.com/googleplay/android-developer/answer/9876821?visit_id=638212471037699574-3659113512&rd=1
        // UPD: пробуем вернуть
        const Component = ShareContactsMessage.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value} />;
        }
    } else if (value.type.startsWith("sign-up")) {
        const Component = SignUp.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value} />;
        }
    } else if (value.type.startsWith("agreement")) {
        const Component = Agreement.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value} />;
        }
    } else if (value.type.startsWith("restructuring")) {
        const Component = Restructuring.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value} />;
        }
    } else if (value.type.startsWith("promo-page")) {
        const Component = PromoPage.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value} />;
        }
    } else if (value.type.startsWith("calculator")) {
        const Component = Calculator.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value} />;
        }
    } else if (value.type.startsWith("face-id")) {
        const Component = SmileId.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value}/>;
        }
    } else if (value.type.startsWith("card")) {
        const Component = Card.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value}/>;
        }
    } else if (value.type.startsWith("direct-debit")) {
        const Component = DirectDebit.MessageComponentFactory(value.type);
        if (Component) {
            return <Component value={value}/>;
        }
    } else
        switch (value.type) {
            case "text":
                const message = value as Chat.TextMessage;
                return (
                    <Base.MarkdownMessage
                        source={message.author}
                        at={message.time}
                        content={message.markdown}
                        isDebug={"isDebug" in message && message.isDebug}
                    />
                );
            case "delay":
                return <Base.DelayMessage value={value} />;
            case "redirect":
                return <Base.RedirectMessage to={value.to} />;
            case "refresh-client-state":
                return <Base.RefreshClientStateMessage />;
            case "timeout":
                return <TimeoutMessage value={value} />;
            case "text-editable":
                return <Base.TextMessageEditable value={value} />;
            case "editable":
                return <Base.EditableMessage value={value} />;
            case "info:load":
                return <Base.LoadMessage />;
        }
    return <FailureMessage details={value} />;
};
