import {AxiosInstance, AxiosResponse} from "axios";
import {Conditions} from "./conditions";
import * as Credit from "./credit";
import {State} from "./state";
import {User} from "./user";
import * as PromoPage from "./promo-page";
import * as Configuration from "./configuration";
import * as Review from "./review";

export type AuthResponse = { id: number, access: string, refresh: string };

export class GuestApi {
    constructor(
        protected readonly endpoint: AxiosInstance,
        public readonly promoPage = PromoPage.Api(endpoint),
        public readonly configuration = Configuration.Api(endpoint),
        public readonly review = Review.Api(endpoint),
    ) {
    }

    public readonly sign = {
        request: async (phone: string): Promise<boolean> => {
            const response = await this.endpoint.post<void>("/v2/sign/request", {Request: {phone}}, {
                validateStatus: (status: number) => [403, 423, 200].includes(status),
            });
            return response.status !== 403;
        },
        confirm: async (phone: string, token: string): Promise<AuthResponse> => {
            const response = await this.endpoint.post("/v2/sign/confirm", {Confirm: {phone, token,}}, {
                validateStatus: (status: number) => [200, 201,].includes(status),
            });
            return response.data;
        },
        refresh: async (refreshToken: string): Promise<AuthResponse | false> => {
            const response: AxiosResponse<AuthResponse> = await this.endpoint.put("/v3/auth", {}, {
                params: {
                    refresh: refreshToken,
                },
                validateStatus: (status) => [201, 400].includes(status),
            });
            if (response.status === 400) {
                return false;
            }
            return response.data;
        },
    } as const;

    public readonly getState = (token: string): Promise<State | undefined> => {
        return this.endpoint.get<{ user: Omit<User, "token">, request: Credit.Request }>(
            "/v2/state",
            {
                validateStatus: (status) => (status === 200) || (status === 401),
                headers: {Authorization: `Bearer ${token}`},
            }
        )
            .then((response) => (response.status === 200) ? {
                user: {
                    ...response.data.user,
                    token,
                },
                request: {
                    ...response.data.request,
                },
            } : undefined);
    };

    public readonly getConditions = (): Promise<Conditions> => {
        return this.endpoint.get<Conditions>("/v2/conditions")
            .then(({data}) => Object.freeze(data));
    };
}
