import {Capacitor,} from '@capacitor/core';
import {FirebaseAnalytics} from '@capacitor-firebase/analytics';
import {AFInit, AppsFlyer} from "appsflyer-capacitor-plugin";
import {AppUpdate, AppUpdateAvailability, AppUpdateInfo} from "@capawesome/capacitor-app-update";
import {FlexibleStorage} from "flexible-storage";
import {initCapacitorBrowser} from "./browser";
import {getCurrentConfig} from "../../app/config";
import {initPushNotification} from "./push-notifications";
import {History} from "history";
import {grabAllDeviceInfo} from "./device";
import {initKochavaSdk} from "./kochava";
import { initAdjustSdk } from "./adjust-sdk";

export const CapacitorFlexibleStorage: FlexibleStorage = new FlexibleStorage(window.localStorage, "capacitor:");

declare global {
    interface Window {
        installReferrer?: {
            getReferrer: (
                success: (installReferrerObj: installReferrerObj) => void,
                error: (error: string) => void
            ) => void;
        };
    }
}

export interface installReferrerObj {
    clickTs: string;
    installTs: string;
    isInstantExperienceLaunched: string;
    responseCode: installReferrerResponseCode;
    utm_medium: string;
    utm_source: string;
    originalReferrer: string;
}
export enum installReferrerResponseCode {
    OK = "0",
    FEATURE_NOT_SUPPORTED = "1",
    SERVICE_UNAVAILABLE = "2"
}

function initClasses() {
    document.body.classList.add('capacitor');
    document.body.classList.add(`capacitor__${Capacitor.getPlatform()}`);
    document.documentElement.classList.add('capacitor');
    document.documentElement.classList.add(`capacitor__${Capacitor.getPlatform()}`);
}

async function initAnalytics() {
    await FirebaseAnalytics.setEnabled({
        enabled: true,
    });
}

async function initAppsFlyer(): Promise<void> {
    const devKey: string | undefined = getCurrentConfig().config.appsFlyerKey;

    if (!devKey) {
        return;
    }

    const afConfig: AFInit = {
        appID: '', // replace with your app ID (IOS).
        devKey,
        isDebug: process.env.NODE_ENV?.toLowerCase() !== 'production',
        minTimeBetweenSessions: 6, // default 5 sec
        registerOnDeepLink: false,
        registerConversionListener: true,
        registerOnAppOpenAttribution: false,
        deepLinkTimeout: 4000, // default 3000 ms
    };

    AppsFlyer.initSDK(afConfig).catch((err) => {
        // 403 error when limit is reached, ignore
        console.warn('AppsFlyer.initSDK catch ', err);
    });
}

async function initUpdateApp(): Promise<void> {
    // ios temporary ignored
    if(Capacitor.getPlatform() !== 'android') {
        return;
    }

    const appUpdateInfo: AppUpdateInfo = await AppUpdate.getAppUpdateInfo();

    if(appUpdateInfo.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE){
       return;
    }

    if (appUpdateInfo.immediateUpdateAllowed) {
       await AppUpdate.performImmediateUpdate();
    } else if (appUpdateInfo.flexibleUpdateAllowed) {
       await AppUpdate.startFlexibleUpdate();
    }
}
export async function initCapacitorPlugins(history: History) {
    if (!Capacitor.isNativePlatform()) {
        return;
    }

    try {
        initKochavaSdk();
    } catch (e) {
        console.warn(e);
        // Ignore Error
    }

    try {
        initAdjustSdk();
    } catch (e) {
        console.warn(e);
        // Ignore Error
    }

    try {
        await initAnalytics();
    } catch (e) {
        console.warn(e);
        // Ignore Error
    }

    try {
        initClasses();
    } catch (e) {
        console.warn(e);
        // Ignore Error
    }

    try {
        initCapacitorBrowser();
    } catch (e) {
        console.warn(e);
        // Ignore Error
    }

    try {
        await initPushNotification(history);
    } catch (e) {
        console.warn(e);
        // Ignore Error
    }

    try {
        await grabAllDeviceInfo();
    } catch (e) {
        console.warn(e);
        // Ignore Error
    }

    await initAppsFlyer();

    try {
        await initUpdateApp();
    } catch (e) {
        console.warn(e);
        // Ignore Error
    }
}
