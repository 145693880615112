import { useLocale } from "app/locale";
import { useEffect } from "react";
import * as Chat from "modules/chat";
import * as Client from "modules/client";
import * as Base from "../base";
import axios, { AxiosError } from "axios";
import * as State from "../state";
import { useHistory } from "react-router-dom";

export const ConfirmMessage = Client.withApi<
    State.MessageProps<Chat.SignUp.ConfirmMessage>
>(({ value, api }) => {
    const dispatch = Chat.useDispatchContext();
    const history = useHistory();
    const { t } = useLocale("registration");
    useEffect(() => {
        const cancelToken = axios.CancelToken.source();
        api.with({ cancelToken: cancelToken.token })
            .guest.sign.confirm(value.phone, value.token)
            .then((data) => {
                dispatch(
                    new Chat.FlushAction([
                        new Chat.SignUp.CompleteMessage(
                            data.access,
                            data.refresh
                        ),
                        new Chat.RedirectMessage("/chat"),
                    ])
                );
            })
            .catch((error: AxiosError | any) => {
                if (!error.response) {
                    return Promise.reject(error);
                }
                if (error.response.status === 400) {
                    dispatch(
                        new Chat.ReplaceAction([
                            new Chat.TextMessage(
                                error.response.data.errors
                                    .map(
                                        ({ details }: { details: string }) =>
                                            details
                                    )
                                    .join(", ")
                            ),
                            new Chat.SignUp.ConfirmFormMessage(value.phone),
                        ])
                    );
                } else if (error.response.status === 423) {
                    dispatch(
                        new Chat.ReplaceAction([
                            new Chat.TextMessage("Maximum attempts reached. Please try again later."),
                            new Chat.SignUp.RequestFormMessage(),
                        ])
                    );
                } else {
                    return Promise.reject(error);
                }
            })
            .catch((error) => {
                if (!axios.isCancel(error)) {
                    history.push("/failure");
                    return Promise.reject(error);
                }
            });
        return () => cancelToken.cancel();
    }, [value.phone]);

    return <Base.LoadMessage />;
});
