import * as React from "react";
import * as Base from "../base";
import * as Chat from "modules/chat";
import * as State from "app/chat/state";

export const InitMessage: State.Message<
    State.MessageProps<Chat.Card.InitMessage>
> = () => {
    return <Base.WrapMessage source="bot">
        <p>To help ensure a smooth loan repayment experience, we require you to link a credit or debit card to your
            personal account. This will allow for automatic deductions when your loan repayments are due, providing you
            a hassle-free process and helping you avoid late payment penalties.</p>
        <p>For security reasons and to verify your card, we will make a small transaction of 50 Naira, which will
            immediately be refunded back to you. This process ensures the safety of your information and allows us to
            confirm the validity of your card.</p>
        <p>By proceeding, you confirm that you understand and agree to this procedure.</p>
    </Base.WrapMessage>
};
