import "core-js/features/object/from-entries";
import "./locale/i18n";

import { createElement } from "react";
import * as ReactDOM from "react-dom";

import { ContainerElement } from "modules/container";
import buildTag from "modules/utils/build-tag";
import environment from "modules/utils/environment";

import { Provider } from "./provider";

import { coffee } from "modules/coffee";
import { addCodeToHtml } from "../modules/utils/add-code-to-html";
import { getCurrentConfig } from "./config";
import { Capacitor } from "@capacitor/core";

const liveChatLicenseId: number | undefined =
    getCurrentConfig().config.liveChatLicenseId;

if (liveChatLicenseId) {
    addCodeToHtml(`
    <!-- Start of LiveChat (www.livechat.com) code -->
    <script>
        window.__lc = window.__lc || {};
        window.__lc.license = '${liveChatLicenseId}';
        ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
    </script>
    <noscript><a href="https://www.livechat.com/chat-with/${liveChatLicenseId}" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a></noscript>
    <!-- End of LiveChat code -->
`);
}

if (!Capacitor.isNativePlatform()) {
    coffee();
    addCodeToHtml(`
        <script src="https://cdn.smileidentity.com/inline/v1/js/script.min.js"></script>
    `);
}

ReactDOM.render(
    createElement(Provider),
    ContainerElement({ buildTag, environment })
);
