import { useLocale } from "app/locale";
import axios, { AxiosError } from "axios";
import * as React from "react";

import { Instance } from "./instance";
import { User } from "./user";
import { Capacitor } from "@capacitor/core";
import { useHistory } from "react-router-dom";
import {getCurrentConfig} from "../../../app/config";

import axiosRetry from "axios-retry";
import {CapacitorDeviceInfo, deviceIdRequestInterceptor, grabAllDeviceInfo} from "../../capacitor/device";

export interface State {
    api: Instance;
}

export function State(): State {
    let baseURL = getCurrentConfig().config.baseUrl;
    const endpoint = axios.create({
        headers: Capacitor.isNativePlatform()
            ? {
                  "X-Bobra-Client": `mobile-app/${process.env.VERSION_PACKAGE_JSON}`,
                  accept: "application/json",
              }
            : {},
        baseURL,
        withCredentials: false,
    });

    endpoint.interceptors.request.use(deviceIdRequestInterceptor);

    const id = endpoint.interceptors.response.use(undefined, (error) => {
        endpoint.interceptors.response.eject(id);

        if (
            error.config &&
            error.response &&
            error.response.status === 404 &&
            !("baseURL" in error.config) &&
            !baseURL
        ) {
            endpoint.defaults.baseURL = baseURL = "https://api.kobogo.ng/";
            return endpoint.request(error.config);
        }

        return Promise.reject(error);
    });
    axiosRetry(endpoint, {
        retries: 5,
        retryDelay: axiosRetry.exponentialDelay,
        retryCondition: (error: AxiosError): boolean => {
            return error.message?.includes("timeout") || error.message?.includes("Network Error")
        }
    });
    return {
        api: new Instance(endpoint),
    };
}

export const DefaultState = Object.freeze<State>({ api: new Instance(axios) });

export function useEffect(
    { api }: State,
    auth: User | string | undefined
): void {
    const { currentLanguage } = useLocale();
    const history = useHistory();

    React.useEffect(() => {
        api.endpoint.defaults.headers.common["accept-language"] = "en";
    }, [currentLanguage, api]);

    // todo: add 401 interceptor
    React.useEffect(() => {
        if (auth === undefined) {
            delete api.endpoint.defaults.headers.common.Authorization;
        } else if ("string" === typeof auth) {
            api.endpoint.defaults.headers.common.Authorization = `Bearer ${auth}`;
        }
    }, [auth, api]);

    React.useEffect(() => {
        const interceptorId = api.endpoint.interceptors.response.use(
            (response) => {
                if (response.headers['x-bobra-device-id-unknown']) {
                    sendDeviceInfo(api);
                }
                return response;
            },
            (error: AxiosError) => {
                if (error.response?.status == 426) {
                    history.push("/upgrade-required");
                    return Promise.reject(error);
                }

                return Promise.reject(error);
            }
        );
        return () => api.endpoint.interceptors.response.eject(interceptorId);
    }, [api]);
}


export const sendDeviceInfo = async (api: Instance) => {
    let deviceInfo: Partial<CapacitorDeviceInfo> | null;

    try {
        deviceInfo = JSON.parse(localStorage.getItem('capacitor:device-info') || 'null')
            || await grabAllDeviceInfo();
    } catch (e) {
        deviceInfo = await grabAllDeviceInfo();
    }

    if (!deviceInfo?.deviceId) {
        console.error("DeviceInfo missing deviceId");
        return;
    }

    try {
        await api.user.deviceInfo.postDeviceInfo(deviceInfo.deviceId, deviceInfo);
    }
    catch (error) {
        console.warn('postDeviceInfo error: ', {error});
    }
};
