import { LocalizedRoute } from "app/locale";
import * as React from "react";

import Layout from "./layout";

export const Route: () => React.ReactElement = () => {
    return (
        <LocalizedRoute
            path={[
                "/chat",
                "/chat/signin",
                "/chat/restructuring",
                "/chat/card-success",
                "/p/:promoShortUrl",
                "/b/:promoShortUrl",
            ]}
            exact
            key="chat"
            component={Layout}
        />
    );
};
