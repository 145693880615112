import * as React from "react";
import * as Logout from "./logout";
import * as Failure from "./failure";
import * as NotFound from "./not-found";
import * as UpgradeRequired from "./upgrade-required";

export const routes = (): Array<React.ReactElement> => {
    return [
        Logout.Route(),
        Failure.Route(),
        NotFound.Route(),
        UpgradeRequired.Route(),
    ];
};
