import * as React from "react";
import {Switch, SwitchProps} from "react-router-dom";
import {useLocale} from "../hooks";
import {LOCALE_ROUTE_PATH} from "../i18n";
import {LocalizedRouteProps} from "./route";

export interface LocalizedSwitchProps extends SwitchProps {
}

export const LocalizedSwitch: React.FC<LocalizedSwitchProps> = ({children}) => {
    const {localizeRoutePath} = useLocale();
    
    return <Switch>
        {React.Children.map(children, child => {
            if (React.isValidElement(child)) {
                const localizedPath = localizeRoutePath(child.props.path, item => `${LOCALE_ROUTE_PATH}${item}`);
                
                return React.cloneElement<LocalizedRouteProps>(child, {
                    ...child.props,
                    path: localizedPath
                });
            } else {
                return child;
            }
        })}
    </Switch>;
};
