import * as Client from "modules/client";
import {useTheme} from "modules/hooks/use-theme";
import * as React from "react";

import {RouteComponentProps} from "react-router-dom";
import {LocalizedRedirect} from "app/locale";

export const Page: React.FC<RouteComponentProps> = ({location}) => {
    useTheme();
    const dispatch = Client.useDispatchContext();
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get("access");
    React.useEffect(() => {
        if (!accessToken) {
            return;
        }
        dispatch(new Client.Auth.AuthAction({type: "token", user: accessToken,}));
    }, [accessToken, dispatch]);
    
    return <LocalizedRedirect to="/"/>;
};
