import * as React from "react";
import axios from "axios";
import * as Api from "./api";
import {useHistory} from "react-router-dom";
import {FlexibleStorage} from "flexible-storage";

const storageName = "userHash";

// region State
export type GuestState = {
    type: "guest",
    user: undefined,
};
export type UserState = {
    type: "user",
    user: Api.User,
    request: Api.Credit.Request,
};
export type TokenState = {
    type: "token",
    user: string,
};

export type State = GuestState | TokenState | UserState;
const flexibleStorage = new FlexibleStorage(window.localStorage, "ll.");
export const State = (): State => {
    const token = flexibleStorage.pull(storageName);
    if ("string" === typeof token) {
        return {user: token, type: "token",};
    }
    return GuestState;
};
export const GuestState: GuestState = Object.freeze({user: undefined, type: "guest",});

// endregion

export class AuthAction {
    public readonly type = "auth";

    constructor(public readonly state: State) {
    }
}
export class LoginAction {
    public readonly type = "login";
    constructor(public readonly tokens: TokensTuple) {
    }
}
export class RefreshAction {
    public readonly type = "refresh";
}

export class LogoutAction {
    public readonly type = "logout";
}

export type Action = AuthAction | RefreshAction | LogoutAction | LoginAction;
export type Dispatch = React.Dispatch<Action>;

export const Reducer: React.Reducer<State, Action> = (prevState: State, action): State => {
    switch (action.type) {
        case "auth":
            return action.state;
        case "login":
            updateTokenAndExpiresDate(encodeRefreshToken(action.tokens.refresh));
            return {
                type: "token",
                user: action.tokens.access,
            };
        case "refresh":
            if ("object" !== typeof prevState.user) {
                return prevState;
            }
            return {user: prevState.user.token, type: "token",};
        case "logout":
            flexibleStorage.remove(storageName);
            return GuestState;
    }
};

const refreshTokenPrefix = "refresh:";
const isRefreshToken = (encodedToken: string): boolean => encodedToken.startsWith(refreshTokenPrefix);
const decodeRefreshToken = (encodedToken: string): string => encodedToken.substring(refreshTokenPrefix.length);
const encodeRefreshToken = (token: string): string => refreshTokenPrefix + token;
type TokensTuple = {
    refresh: string;
    access: string;
}

const updateTokenAndExpiresDate = (token: string) => {
    const expires: Date = new Date();
    if (isRefreshToken(token)) {
        expires.setMonth(expires.getMonth() + 1);
    } else {
        expires.setDate(expires.getDate() + 3);
    }
    flexibleStorage.push(storageName, token, expires);
}

const isWait = (user: State["user"]): user is string => "string" === typeof user;

export function useEffect({user}: State, api: Api.Instance, dispatch: (action: Action) => void) {
    const deps = [user, api, dispatch];
    const history = useHistory();

    React.useEffect(() => {
        if (!isWait(user)) {
            return;
        }
        const cancelToken = axios.CancelToken.source();

        const loadUser = (accessToken: string) => {
            api
                .with({cancelToken: cancelToken.token})
                .guest
                .getState(accessToken)
                .then((newState) => {
                    if (newState === undefined) {
                        flexibleStorage.remove(storageName);
                        history.push("/");
                    }
                    dispatch(new AuthAction(newState ? {...newState, type: "user",} : GuestState))
                })
                .catch((error: Error) => {
                    if (axios.isCancel(error)) {
                        return;
                    }
                    flexibleStorage.remove(storageName);
                    dispatch(new AuthAction(GuestState));
                    history.push("/failure");
                    throw error;
                });
        };
        const updateToken = (refreshToken: string) => {
            api
                .with({cancelToken: cancelToken.token})
                .guest.sign.refresh(refreshToken)
                .then((tokens: Api.AuthResponse | false) => {
                    if (tokens === false) {
                        flexibleStorage.remove(storageName);
                        dispatch(new AuthAction(GuestState));
                        history.push("/");
                        return;
                    }
                    updateTokenAndExpiresDate(encodeRefreshToken(tokens.refresh));
                    dispatch(new AuthAction({type: "token", user: tokens.access}))
                })
                .catch((error) => {
                    if (axios.isCancel(error)) {
                        return;
                    }
                    // We are sure that this error does not mean that the token is out of date
                    // as this case is handled in a successful scenario so don't need to remove token from storage
                    dispatch(new AuthAction(GuestState));
                    history.push("/failure");
                    console.error(error);
                });
        };

        isRefreshToken(user)
            ? updateToken(decodeRefreshToken(user))
            : loadUser(user);

        return () => cancelToken.cancel();
    }, deps);
}
