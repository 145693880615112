import "./styles.scss";

export const Layout = () => {
    return (
        <article>
            <h1>Debug info page</h1>
            <div className="container">
                <div className="block">
                    <p className="block__title">BUILD_VERSION_TAG: </p>
                    <p className="block__value">{process.env.BUILD_VERSION_TAG}</p>
                </div>
                <div className="block">
                    <p className="block__title">VERSION_PACKAGE_JSON: </p>
                    <p className="block__value">{process.env.VERSION_PACKAGE_JSON}</p>
                </div>
                <div className="block">
                    <p className="block__title">BASE_URL: </p>
                    <p className="block__value">{process.env.BASE_URL}</p>
                </div>
                <div className="block">
                    <p className="block__title">capacitor:install-referrer: </p>
                    <p className="block__value">{localStorage.getItem('capacitor:install-referrer')}</p>
                </div>
                <div className="block">
                    <p className="block__title">capacitor:device-info: </p>
                    <p className="block__value">{localStorage.getItem('capacitor:device-info')}</p>
                </div>
                <div className="block">
                    <p className="block__title">capacitor:device-id: </p>
                    <p className="block__value">{localStorage.getItem('capacitor:device-id')}</p>
                </div>
                <div className="block">
                    <p className="block__title">capacitor:FCM-token: </p>
                    <p className="block__value">{localStorage.getItem('capacitor:token')}</p>
                </div>
            </div>
        </article>
    );
};

export default Layout;
