import { IconArrow } from "modules/components/Icons/IconArrow";
import Docs from "./docs.svg";

export const Layout = () => {
    return (
        <article>
            <h1>Service documentation</h1>
            <div className="jumbotron">
                <img src={Docs} width="104" height="103" alt="Docs" />
                <p>
                    We guarantee the security of your personal data and are
                    responsible for this. Only authorized managers have access
                    to it, the data is not disclosed to third parties and cannot
                    be intercepted by fraudsters from the site. We use the SSL
                    protocol for information security.
                </p>
            </div>
            <a
                href="https://kobogo.ng/#contact"
                className="btn btn_white full-sized"
                target="_blank"
                rel="noopener noreferrer"
            >
                <span>View all documents</span>
                <IconArrow />
            </a>
        </article>
    );
};

export default Layout;
