import * as React from "react";
import { LocalizedLink } from "app/locale";
import * as Icon from "./icons";
import * as Client from "../../modules/client";
import classNames from "classnames";
import { PhoneList } from "app/chat/base/phone-list";
import { getCurrentConfig } from "../config";

type NavLinkType = {
    path: string;
    label: string;
    icon: React.ReactNode;
};

const linksPrimary: Array<NavLinkType> = [
    {
        path: "about",
        label: "About KoboGo",
        icon: <Icon.NavArrow />,
    },
    {
        path: "contacts",
        label: "Contacts",
        icon: <Icon.NavArrow />,
    },
    {
        path: "faq",
        label: "Help",
        icon: <Icon.NavArrow />,
    },
    {
        path: "docs",
        label: "Service documentation",
        icon: <Icon.NavArrow />,
    },
];

const linksSecondary: Array<NavLinkType> = [];

const navLink = (links: Array<NavLinkType>) => {
    return (
        <>
            {links.map(({ icon, path, label }) => (
                <li key={label}>
                    <LocalizedLink to={path}>
                        <span>{label}</span>
                        {icon}
                    </LocalizedLink>
                </li>
            ))}
        </>
    );
};

export const LinkList = () => {
    const clientState = Client.useStateContext();
    const _linksSecondary = [...linksSecondary];
    const _linksPrimary = [...linksPrimary];

    if (clientState.user) {
        _linksSecondary.push({
            path: "logout",
            label: "Leave account",
            icon: <Icon.Signout />,
        });
    }

    if (getCurrentConfig().environment !== 'production') {
        _linksPrimary.push({
            path: "debug-info",
            label: "DEBUG INFO",
            icon: <Icon.NavArrow />,
        })
    }


    return (
        <nav className="nav">
            <ul
                className={classNames(
                    "nav__primary",
                    _linksSecondary.length
                        ? ""
                        : "nav__primary_no-bottom-border"
                )}
            >
                {navLink(_linksPrimary)}
            </ul>
            <ul className="nav__secondary">{navLink(_linksSecondary)}</ul>
            <div className="phones">
                <PhoneList />
            </div>
            <div className={'nav__build'}>Build: {process.env.BUILD_VERSION_TAG}</div>
        </nav>
    );
};

LinkList.displayName = "Navigation.LinkList";
