import { useCallback } from "react";
import * as React from "react";
import {Redirect, RedirectProps} from "react-router-dom";
import {useLocale} from "../hooks";

export interface LocalizedRedirect extends RedirectProps {
    to: string
}

export const LocalizedRedirect: React.FC<LocalizedRedirect> = (props) => {
    const {joinBasePath} = useLocale();
    
    const localizeRoutePath = useCallback((path?: string) => {
        return joinBasePath(path || "");
    }, [joinBasePath]);
    
    return <Redirect {...props} to={localizeRoutePath(props.to)}/>;
};
