import {ActionPerformed, PushNotifications, PushNotificationSchema, Token} from "@capacitor/push-notifications";
import {History} from "history";

export enum PushNotificationType {
    SUCCESSFUL_LOAN = "outpayment.successful.paystack",
    SUCCESSFUL_LOAN_REPAYMENT = "repayment.successful.paystack",
}
export async function initPushNotification(_history: History): Promise<void> {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus?.receive === 'prompt') {
        permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus?.receive !== 'granted') {
        throw new Error('User denied permissions!');
    }

    // On success, we should be able to receive notifications
    await PushNotifications.addListener('registration',
        (token: Token): void => {
            localStorage.setItem('capacitor:token', token.value);
        }
    ).catch((error): void => {
        console.warn('PushNotifications.addListener(\'registration\') error: ', error)
    });

    // Some issue with our setup and push will not work
    await PushNotifications.addListener('registrationError',
        (error: any): void => {
            console.error('Error on registration: ' + JSON.stringify(error));
        }
    ).catch((error): void => {
        console.warn('PushNotifications.addListener(\'registrationError\') error: ', error)
    });

    /**
     * Обработчик когда пришел пуш в открытое приложение foreground/background
     * В дальнейшем тут обрабатывать что делать при получении пуша,
     * скорее всего ничего потому как приложение открыто
     */
    await PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema): void => {
            console.log('Push received: ', {notification});
        }
    ).catch((error): void => {
        console.warn('PushNotifications.addListener(\'pushNotificationReceived\') error: ', error)
    });

    /**
     * Обработчик когда юзер "нажал" на пуш
     */
    await PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed): void => {
            console.log('Push action performed: ', {notification});
            const paymentId = notification.notification.data['transactionReference'];

            if (notification.notification.data.type === PushNotificationType.SUCCESSFUL_LOAN) {
                _history.push('/dashboard/payment-succeed');
                _history.push('/leave-feedback');
            }

            if (notification.notification.data.type === PushNotificationType.SUCCESSFUL_LOAN_REPAYMENT) {
                if (paymentId) {
                    _history.push(`/dashboard/payment/passed?id=${paymentId}`);
                }

                _history.push('/leave-feedback');
            }
        }
    ).catch((error): void => {
        console.warn('PushNotifications.addListener(\'pushNotificationActionPerformed\') error: ', error)
    });

    await PushNotifications.register().catch((error): void => {
        console.warn('PushNotifications.register() error: ', error)
    });
}
export function unregisterFromPushNotifications(): void {
    PushNotifications.unregister().catch((error): void => {
        console.warn('PushNotifications.unregister() error: ', error)
    });
}
