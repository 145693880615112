import { Capacitor } from "@capacitor/core";
import { Adjust, AdjustConfig, AdjustEnvironment, AdjustEvent } from "@awesome-cordova-plugins/adjust";
import { getCurrentConfig } from "../../app/config";
import * as Sentry from "@sentry/capacitor";


/** Read more https://github.com/adjust/cordova_sdk */
export function initAdjustSdk(): void {
    const adjustAppToken: string | undefined = getCurrentConfig().config.adjustAppToken;

    if (!Capacitor.isNativePlatform() || !adjustAppToken) {
        return;
    }

    try {
        const config: AdjustConfig = new AdjustConfig(adjustAppToken, getCurrentConfig().environment === 'production' ? AdjustEnvironment.Production : AdjustEnvironment.Sandbox);
        Adjust.create(config);
    } catch (e) {
        console.warn(e);
        // Ignore Error
    }
}

export function sendAdjustSdkEvent(name: string) {
    const adjustAppToken: string | undefined = getCurrentConfig().config.adjustAppToken;

    if (!Capacitor.isNativePlatform() || !adjustAppToken || !name) {
        return;
    }

    try {
        const adjustEvent: AdjustEvent = new AdjustEvent(name);
        Adjust.trackEvent(adjustEvent);
    } catch (error) {
        console.warn(error);
        Sentry.captureMessage(
            `Error in sendAdjustSdkEvent: ${name}`,
            {
                level: 'error',
                extra: {error}
            }
        );
    }
}
