import React, { useEffect } from "react";
import { Header } from "./header";
import Provider from "./provider";
import { BotOverlay } from "modules/components/bot/overlay";
import * as Main from "modules/components/main";
import { useTheme } from "modules/hooks/use-theme";
import { Section } from "./section";
import { useHead } from "modules/hooks/use-head";
import * as Client from "modules/client";
import { useBodyClassName } from "modules/hooks/use-body-class-name";
import "modules/halloween/dark-theme.scss";
import "./styles.scss";

export const Layout = Client.withState<Pick<Client.State, "type">>(
    ({ type }) => {
        useEffect(() => {
            if (type === "guest") {
                document.body.classList.add("show-footer");
            }
            return () => document.body.classList.remove("show-footer");
        }, []);

        useTheme("chat");
        //TODO: change meta
        useHead("KoboGo", "KoboGo");
        useBodyClassName("dark-theme");

        return (
            <>
                <Header />
                <BotOverlay />
                <Main.Layout>
                    <Section>
                        <div className="chat-stream">
                            <div className="chat-list">
                                <Provider />
                            </div>
                        </div>
                    </Section>
                </Main.Layout>
            </>
        );
    },
    (state) => {
        return {
            type: state.type,
        };
    }
);

Layout.displayName = "Chat.Layout";

export default Layout;
