import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import {Capacitor} from "@capacitor/core";
import {AppsFlyer} from "appsflyer-capacitor-plugin";
import {FacebookLogin} from "@capacitor-community/facebook-login";
import {getCurrentConfig} from "../../app/config";
import {sendKochavaEvent} from "../capacitor/kochava";
import { sendAdjustSdkEvent } from "../capacitor/adjust-sdk";

const dataLayer = (window as any).dataLayer = (window as any).dataLayer || [];

export function gtag(...args: any) {
    dataLayer.push(arguments);
}

(window as any).gtag = gtag;
gtag('js', new Date());
gtag('config', 'AW-10826901037');

export function sendConversion(type: "registration"): void;
export function sendConversion(type: "credit", number: number, id?: number): void
export function sendConversion(
    type: "registration" | "credit",
    number?: number/* порядковый номер заявки */,
    id?: number /* id заявки */
): void {
    if (getCurrentConfig().environment !== 'production') {
        return;
    }

    if (type === "registration") {
        gtag('event', 'conversion', {'send_to': 'AW-10826901037/hj0jCL-outcDEK3M1aoo'});
        if (Capacitor.isNativePlatform()) {
            FirebaseAnalytics.logEvent({name: type});
            // @ts-ignore https://github.com/capacitor-community/facebook-login/issues/144
            FacebookLogin.logEvent({eventName: type});
            sendKochavaEvent(type);
            sendAdjustSdkEvent(type);
        }

        return;
    }

    if (Capacitor.isNativePlatform()) {
        const eventName = Number(number) > 0 ? 'second_credit_taken' : 'first_credit_taken';
        FirebaseAnalytics.logEvent({
            name: eventName,
            params: {
                id,
                number,
            }
        });

        AppsFlyer.logEvent({
            eventName: eventName,
            eventValue: {
                id,
                number,
            }
        }).catch((error) => {
            /** ignoring this error because it's not necessary */
            console.warn(error);
        });

        // @ts-ignore https://github.com/capacitor-community/facebook-login/issues/144
        FacebookLogin.logEvent({eventName: eventName})

        sendKochavaEvent(eventName);
        sendAdjustSdkEvent(type);
    }

    if (Number(number) > 0) {
        gtag('event', 'conversion', {'send_to': 'AW-10826901037/LF8vCLCwjIAYEK3M1aoo'});
    } else {
        gtag('event', 'conversion', {'send_to': 'AW-10826901037/ksJtCL60jIAYEK3M1aoo'});
    }
}
