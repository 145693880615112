import * as React from "react";
import * as Client from "modules/client";
import * as Configuration from "modules/configuration";
import {isRequestOverdueCollection} from "modules/utils/is-request-overdue-collection";

export type PhoneNumbersProps = {
    phones: string[];
}

export const withPhoneNumbers = <T>(
    component: React.ComponentType<T & PhoneNumbersProps>
): React.ComponentType<T> => {
    const componentType: React.ComponentType<T> =
        (props: T) => {
            const state = Client.useStateContext();
            const config = React.useContext(Configuration.Context);
            const phones = isRequestOverdueCollection(state)
                ? config.phones.collection
                : config.phones.support;

            const childProps: T & PhoneNumbersProps = {
                ...props,
                phones,
            };

            return React.createElement(component, childProps);
        };
    componentType.displayName = `withPhoneNumbers(${
        component.displayName || (component as any).name
    })`;
    return componentType;
}
