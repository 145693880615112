import * as React from "react";
import * as State from "app/chat/state";
import * as Chat from "modules/chat";
import * as Base from "../base";
import * as Mono from "modules/mono.co";
import {useHistory} from "react-router-dom";

export const WidgetMessage: State.Message<
    State.MessageProps<Chat.BankId.WidgetMessage>
> = ({ value }) => {
    const dispatch = Chat.useDispatchContext();
    const history = useHistory();
    console.log(`Loading Mono widget with public key: ${value.publicKey}`);

    const monoConnect = React.useMemo(() => {
        if (!value.publicKey) {
            return;
        }
        const monoInstance = new Mono.Connect({
            onClose: () => {},
            onLoad: () => console.log("Widget loaded successfully"),
            onSuccess: ({ code }: { code: string }) => {
                if (value.reauthToken) {
                    history.push("/registration/mono/details");
                }
                dispatch(
                    new Chat.ReplaceAction([new Chat.BankId.LoadMessage(code)])
                );
                monoInstance.close();
            },
            onEvent: (...args) => console.warn({ args }),
            key: value.publicKey,
        });

        monoInstance.setup(value.reauthToken ? {
            reauth_token: value.reauthToken,
        }: {});

        return monoInstance;
    }, [value.publicKey, value.reauthToken]);

    return (
        <Base.ButtonMessage source="user">
            <Base.Button theme={"secondary"} onClick={monoConnect?.open}>
                Link account with Mono
            </Base.Button>
        </Base.ButtonMessage>
    );
};
