import firebase from "firebase/compat";
import {getCurrentConfig} from "../../app/config";

declare global {
    interface Window {
        KochavaTrackerEventType?: KochavaTrackerEventType,
        KochavaTrackerLogLevel: KochavaTrackerLogLevel,
        kochavaTrackerInitCompleted: any | Array<any>,
        KochavaTracker: {
            instance: {
                _internal: {
                    registeredAndroidAppGuid: any;
                    registeredIosAppGuid: any;
                    registeredPartnerName: any;
                },

                buildEventWithEventName: (eventName: any) => any,
                buildEventWithEventType: (eventName: any) => any,
                enableAndroidInstantApps(instantAppGuid: any): void;
                enableIosAppClips(identifier: any): void;
                enableIosAtt(): void;
                executeAdvancedInstruction(name: any, value: any): void;
                getDeviceId(): Promise<any>;
                getInstallAttribution(): Promise<any>;
                getStarted(): Promise<any>;
                processDeeplink(path: any): Promise<any>;
                processDeeplinkWithOverrideTimeout(path: any, timeout: any): Promise<any>;
                registerAndroidAppGuid(androidAppGuid: any): void;
                registerCustomBoolValue(name: any, value: any): void;
                registerCustomDeviceIdentifier(name: any, value: any): void;
                registerCustomNumberValue(name: any, value: any): void;
                registerCustomStringValue(name: any, value: any): void;
                registerDefaultEventBoolParameter(name: any, value: any): void;
                registerDefaultEventNumberParameter(name: any, value: any): void;
                registerDefaultEventStringParameter(name: any, value: any): void;
                registerDefaultEventUserId(value: any): void;
                registerIdentityLink(name: any, value: any): void;
                registerIosAppGuid(iosAppGuid: any): void;
                registerPartnerName(partnerName: any): void;
                registerPrivacyProfile(name: any, keys: any): void;
                registerPushToken(token: any): void;
                retrieveInstallAttribution(): Promise<any>;
                endEvent(name: any): void;
                sendEventWithString(name: any, data: any): void;
                sendEventWithDictionary(name: any, data: any): void;
                setAppLimitAdTracking(appLimitAdTracking: any): void;
                setInitCompletedListener(initCompletedListener: any): void;
                setIntelligentConsentGranted(granted: any): void;
                setIosAttAuthorizationAutoRequest(autoRequest: any): void;
                setIosAttAuthorizationWaitTime(waitTime: any): void;
                setLogLevel(logLevel: any): void;
                sendEvent(event: string): void;
                setPrivacyProfileEnabled(name: any, enabled: any): void;
                setPushEnabled(enabled: any): void;
                setSleep(sleep: any): void;
                shutdown(deleteData: any): void;
                start(): void;
            }
        }
    }
}

export type KochavaTrackerEventType = {
    Achievement:          "Achievement",
    AddToCart:            "Add to Cart",
    AddToWishList:        "Add to Wish List",
    CheckoutStart:        "Checkout Start",
    LevelComplete:        "Level Complete",
    Purchase:             "Purchase",
    Rating:               "Rating",
    RegistrationComplete: "Registration Complete",
    Search:               "Search",
    TutorialComplete:     "Tutorial Complete",
    View:                 "View",
    AdView:               "Ad View",
    PushReceived:         "Push Received",
    PushOpened:           "Push Opened",
    ConsentGranted:       "Consent Granted",
    Deeplink:             "_Deeplink",
    AdClick:              "Ad Click",
    StartTrial:           "Start Trial",
    Subscribe:            "Subscribe"
};

export type KochavaTrackerLogLevel = {
    None:  "none",
    Error: "error",
    Warn:  "warn",
    Info:  "info",
    Debug: "debug",
    Trace: "trace"
};


export function initKochavaSdk() {
    const kochavaGUID: string | undefined = getCurrentConfig().config.kochavaGUID;

    if (!window.KochavaTracker || !kochavaGUID) {
        return;
    }

    window.KochavaTracker.instance.registerAndroidAppGuid(kochavaGUID);
    window.KochavaTracker.instance.start();
}

export function sendKochavaEvent(name: string) {
    const kochavaGUID: string | undefined = getCurrentConfig().config.kochavaGUID;

    if (!window.KochavaTracker || !kochavaGUID || !name) {
        return;
    }

    window.KochavaTracker.instance.sendEvent(name);
}
