import * as React from "react";

export const PhoneLink: React.FC<{ phoneNumber: string }> = ({
    phoneNumber,
    children,
}) => {
    return (
        <a href={`tel:${phoneNumber}`} className="phone-link">
            {children}
            &nbsp;
            <span>
                {phoneNumber.replace(
                    /^234(\d{3})(\d{3})(\d{4})$/,
                    "+234 $1 $2 $3"
                )}
            </span>
        </a>
    );
};
PhoneLink.displayName = "Contacts.PhoneLink";
