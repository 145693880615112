import * as React from "react";
import * as CurrentCredit from "./current-credit";
import * as Payment from "./current-credit/payment/Route";
import * as Invoice from "./current-credit/invoice/route";
import * as PaymentCards from "./payment-cards";
import * as PersonalInfo from "./personal-info";
import * as RequestsHistory from "./requests-history";
import * as Nav from "app/dashboard/nav";
import * as Processing from "./processing";
import * as Reject from "./reject";
import * as Confirm from "./confirm";
import * as Accepted from "./accepted";
import * as Paid from "./paid";
import * as PaymentPassed from "./payment-passed";
import * as ProlongationPassed from "./prolongation-passed";
import * as PaymentSucceed from "./payment-succeed";
import * as PaymentCardAdded from "./payment-card-added";
import { LocalizedRedirect, LocalizedRoute, LocalizedSwitch } from "app/locale";
import * as Client from "modules/client";
import * as Restructuring from "./restructuring";
import * as Sold from "./sold";

const Page: React.FC<{}> = () => {
    const state = Client.useStateContext();

    switch (state.type) {
        case "user":
            return <LocalizedSwitch>{routes()}</LocalizedSwitch>;
        case "guest":
            return <LocalizedRedirect key="redirect" to="/" />;
        default:
            return null;
    }
};

export const Route = () => {
    return (
        <LocalizedRoute key="dashboard" path="/dashboard" component={Page} />
    );
};

export function routes(): Array<React.ReactNode> {
    return [
        CurrentCredit.Route(),
        PaymentPassed.Route(),
        Payment.Route(),
        Invoice.Route(),
        PaymentCards.Route(),
        PersonalInfo.Route(),
        RequestsHistory.Route(),
        Nav.Route(),
        Processing.Route(),
        Reject.Route(),
        Confirm.Route(),
        Accepted.Route(),
        Paid.Route(),
        ProlongationPassed.Route(),
        PaymentCardAdded.Route(),
        Restructuring.Route(),
        Sold.Route(),
        PaymentSucceed.Route(),
    ];
}
