import * as Utils from "./utils";

export interface ConnectConfig {
    onClose: () => void,
    onLoad: () => void,
    onSuccess: (response: { code: string }) => void,
    onEvent: (name: string, data: {[k: string]: any}) => void;
    key: string;
}

export class Connect {
    constructor(public readonly config: ConnectConfig) {
    }

    public setup = (config: { [k: string]: any } = {}) => {
        Utils.addStyle();

        const qs = {...this.config, ...config};

        Utils.init({
            key: this.config.key,
            qs,
            onload: this.config.onLoad,
            onevent: this.config.onEvent,
        })
    }

    public reauthorise = (reauth_token: string) => {
        if(!reauth_token) {
            throw new Error("Re-auth token is required for reauthorisation");
        }

        Utils.addStyle();

        const qs = {...this.config, reauth_token};

        Utils.init({
            key: this.config.key,
            qs,
            onload: this.config.onLoad,
            onevent: this.config.onEvent,
        })
    }

    public close = () => {
        Utils.closeWidget();
        window.removeEventListener("message", this.handleEvents, false);
        if (typeof this.config.onClose === "function") {
            this.config.onClose();
        }
    }

    public open = () => {
        Utils.openWidget();
        window.addEventListener("message", this.handleEvents, false);
    }

    protected handleEvents = (event: Utils.MonoEvent): void => {
        switch (event.data.type) {
            /* Old callbacks */
            case "mono.connect.widget.charge_complete":
            case "mono.connect.widget.account_linked":
                this.config.onSuccess({...event.data.data} as any);
                this.config.onEvent('SUCCESS', event.data.data);
                this.close(); // close widget on success
                break;
            case "mono.connect.widget.closed":
                this.close();
                break;
            /* New onEvent callbacks */
            /* LOADED event is not triggered here, look in utils.js */
            case "mono.connect.widget_opened":
                this.onEvent('OPENED', event.data.data);
                break;
            case "mono.connect.error_occured":
                this.onEvent('ERROR', event.data.data);
                break;
            case "mono.connect.institution_selected":
                this.onEvent('INSTITUTION_SELECTED', event.data.data);
                break;
            case "mono.connect.auth_method_switched":
                this.onEvent('AUTH_METHOD_SWITCHED', event.data.data);
                break;
            case "mono.connect.on_exit":
                this.onEvent('EXIT', event.data.data);
                break;
            case "mono.connect.login_attempt":
                this.onEvent('SUBMIT_CREDENTIALS', event.data.data);
                break;
            case "mono.connect.mfa_submitted":
                this.onEvent('SUBMIT_MFA', event.data.data);
                break;
            case "mono.connect.account_linked":
                this.onEvent('ACCOUNT_LINKED', event.data.data);
                break;
            case "mono.connect.account_selected":
                this.onEvent('ACCOUNT_SELECTED', event.data.data);
                break;
        }
    }

    protected onEvent = (name: string, data: object) => {
        if (!this.config.onEvent) {
            return;
        }
        this.config.onEvent(name, data);
    }
}
