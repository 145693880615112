import * as React from "react";
import * as Icon from "./icons";

const Email: React.FC<{ value: string }> = ({ value }) => {
    return (
        <li>
            <a href={`mailto:${value}`}>
                <Icon.Email />
                <div className="align">
                    <h4>Email</h4>
                    <span>{value}</span>
                </div>
            </a>
        </li>
    );
};

const Phone: React.FC<{ value: string }> = ({ value }) => {
    return (
        <li>
            <a href={`tel:${value}`}>
                <Icon.Email />
                <div className="align">
                    <h4>Phone</h4>
                    <span>{value}</span>
                </div>
            </a>
        </li>
    );
};

export const Contacts = () => {
    return (
        <ul className="contacts">
            <Email value="info@kobogo.ng" />
        </ul>
    );
};
