import {LocalizedNavLink, useLocale} from "app/locale";
import {LocationDescriptorObject} from "history";
import * as Client from "modules/client";
import * as React from "react";

export interface LinkItem {
    title: string;
    link: string | LocationDescriptorObject<any>;
}

export type LinkItemList = Array<LinkItem>;

const ListItem: React.FC<{ value: LinkItem }> = ({value: {link, title}}) => {
    const {t} = useLocale("nav-list");

    const path = usePath(link);

    const listItemId = path?.match(/.*(\/.+)/)?.[1];
    return (
        <li id={`id-${listItemId?.slice(1)}`} className="nav-list__item">
            <LocalizedNavLink to={link} exact>{t(title)}</LocalizedNavLink>
        </li>
    );
};
ListItem.displayName = "Dashboard.Nav.Item";

export const List: React.FC<{ items: LinkItemList }> = ({items}) => {
    return (
        <ul className="nav-list">
            {items.map((item) => <ListItem value={item} key={usePath(item.link)}/>)}
        </ul>
    );
};
List.displayName = "Dashboard.Nav.List";

const usePath = (link: string | LocationDescriptorObject<any>) => {
    return typeof link === "string" ? link : link.pathname;
};

export const useDashboardList = (): LinkItemList => {
    const state = Client.useStateContext();

    if (state.type === "user" && state.request.debt?.prolongation != null) {
        return [
            ...dashboardList,
            {
                title: "extend",
                link: {
                    pathname: "/dashboard/payment",
                    state: {
                        type: "prolongation"
                    }
                }
            }
        ];
    }

    return dashboardList;
};

export const dashboardList: LinkItemList = [
    {
        title: "my-credit",
        link: "/dashboard",
    },
    {
        title: "credit-history",
        link: "/dashboard/requests-history",
    },
    {
        title: "my-cards",
        link: "/dashboard/payment-cards",
    },
    {
        title: "personal-data",
        link: "/dashboard/personal-info",
    },
];

export const homeList: LinkItemList = [
    {
        title: "about-us",
        link: "/about",
    },
    {
        title: "how-to-get-loan",
        link: "/next-credit",
    },
    {
        title: "how-to-return-loan",
        link: "/return-credit",
    },
    {
        title: "extend-credit",
        link: "/return-credit#renewal",
    },
    {
        title: "promo",
        link: "/promo/preview",
    },
    {
        title: "help",
        link: "/faq",
    },
];
