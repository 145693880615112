import * as React from "react";

export const LoadingSpinner: React.FC = React.memo(() => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="200px"
        height="200px"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        className="loading-spinner"
    >
        <circle
            cx="50"
            cy="50"
            fill="none"
            stroke="#1991f4"
            strokeWidth="10"
            r="35"
            strokeDasharray="164.93361431346415 56.97787143782138"
        >
            <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="1s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
            ></animateTransform>
        </circle>
    </svg>
));
LoadingSpinner.displayName = "LoadingSpinner";
